<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <div>
    <LoadScript
      v-if="showDsfSkindrWrapper"
      :attributes="{ type: 'module' }"
      src="https://dsf-cdn.loreal.io/dropper.js"
      @loaded="onDropperLoad"
    >
      <dsf-app :switch_ecom="turnOnEcomCta" v-bind="config" />
    </LoadScript>

    <transition name="modal">
      <div v-show="showModal" class="modal">
        <div class="modal__mask" @click="toggleModal"></div>
        <div class="modal__content">
          <div class="modal__inner -morning">
            <button class="modal__close" @click="toggleModal">
              <svg class="icon" role="img">
                <title>{{ labels.closeButtonTitle }}</title>
                <use xlink:href="#close" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
              </svg>
            </button>

            <strong>{{ labels.morningRoutineTitle }}</strong>
            <p>{{ labels.morningRoutineSubtitle }}</p>
            <a
              class="add-to-wallet"
              :href="morningRoutineWalletUrl"
              target="_blank"
              @click="morningRoutineAnalytics"
            >
              <img :src="walletImage" alt="" />
              <span>{{ labels.morningRoutineButtonLabelInModal }}</span>
            </a>
          </div>

          <div class="modal__inner -night">
            <strong>{{ labels.nightRoutineTitle }}</strong>
            <p>{{ labels.nightRoutineSubtitle }}</p>
            <a
              class="add-to-wallet"
              :href="nightRoutineWalletUrl"
              target="_blank"
              @click="nightRoutineAnalytics"
            >
              <img :src="walletImage" alt="" />
              <span>{{ labels.nightRoutineButtonLabelInModal }}</span>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LoadScript from '../../../../../../Foundation/Core/code/Scripts/loadscript/loadscript.vue';
import { mixinParseURL } from '@Foundation/mixins/parseURL/parseURL';
// Helper to generate the wallet
import generateSingleWalletImage from './walletImageGenerator';
// Image assets
import { AnalyticsHandler } from '@Foundation';
import WalletIcon from './assets/WalletIcon.svg';
import MorningRoutineModalBackground from './assets/MorningRoutineModalBackground.png';
import NightRoutineModalBackground from './assets/NightRoutineModalBackground.png';
import {
  ADD_TO_WALLET_BUTTON_CLASS,
  ADD_TO_WALLET_BUTTON_LOADING_CLASS,
  ADD_TO_WALLET_BUTTON_STYLE,
  ADD_TO_WALLET_BUTTON_TYPE,
  ROUTINE_TYPE_MORNING,
  ROUTINE_TYPE_NIGHT,
  SERVICE_BUTTON_CLASS,
  SERVICE_SELECTOR,
  SERVICE_TRIGGER_URL_PARAM_KEY,
} from './settings';

const analyticsCommonFields = {
  type: 'userActionEvent',
  event_name: 'body_button_click',
  category: 'product detail page',
  action: 'select::add to my wallet',
};

export default {
  name: 'OapDsfAppSkindrWrapper',

  components: {
    LoadScript,
  },

  mixins: [mixinParseURL],

  props: {
    config: { type: Object, required: true },
    labels: { type: Object, required: true },
    morningCampaignId: { type: String, required: true },
    nightCampaignId: { type: String, required: true },
    urlToFetch: { type: String, required: true },
    skinDrAppVersion: { type: Number, default: 2 },
    isUsingCrm: { type: Boolean, default: false },
    buttonBottomPosition: { type: Number, default: 116 },
  },

  data() {
    return {
      observerForDocumentBody: null,
      turnOnEcomCta: true,
      elementDsfApp: null,
      elementShadowRoot: null,
      elementSkinDrV3Content: null,
      elementAddToWalletButton: null,

      showModal: false,

      isRoutineDataCollected: false,
      morningRoutineData: [],
      nightRoutineData: [],

      walletImage: WalletIcon,
      morningRoutineWalletBackgroundImage: MorningRoutineModalBackground,
      nightRoutineWalletBackgroundImage: NightRoutineModalBackground,
      morningRoutineWalletImage: null,
      nightRoutineWalletImage: null,

      morningRoutineWalletUrl: '',
      nightRoutineWalletUrl: '',

      analyticsProductInfo: '',
      analyticsMorningProductInfo: '',
      analyticsNightProductInfo: '',
      showDsfSkindrWrapper: false,
    };
  },

  created() {
    window.addEventListener('load', () => {
      this.showDsfSkindrWrapper = true;
    });
  },

  async mounted() {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let paramsValue = params.utm_source == 'pos';
    if (paramsValue) {
      this.turnOnEcomCta = false;
    }
    await this.$nextTick();
    this.observeDsfApp();
  },

  methods: {
    onDropperLoad() {
      window.dsfApp?.addEventListener('onLoadComplete', this.onLoadComplete);
    },

    onLoadComplete() {
      const clickServiceButton = document.body.querySelector(`.${SERVICE_BUTTON_CLASS}`);

      if (this.URLHasKey(SERVICE_TRIGGER_URL_PARAM_KEY)) {
        clickServiceButton.click();
      }
    },

    observeDsfApp() {
      this.observerForDocumentBody = new MutationObserver(() => {
        this.elementDsfApp = document.querySelector(SERVICE_SELECTOR);

        if (!this.elementDsfApp) return;

        this.elementShadowRoot = this.elementDsfApp.shadowRoot;

        if (!this.elementShadowRoot) return;

        if (this.isRoutineDataCollected) return;

        if (this.skinDrAppVersion === 2) {
          const routines = this.elementShadowRoot.querySelector('.analysis-routines');

          if (!routines) return;

          this.querySkinDrV2RoutinesBlock(routines);
        } else if (this.skinDrAppVersion === 3) {
          // no need to observe document.body anymore - we do have a node to grab info from
          this.observerForDocumentBody.disconnect();

          window.dsfApp.addEventListener('onPrescriptionChanged', (data) => {
            this.onSkinDrV3Event(data);
          });
        }
      });

      this.observerForDocumentBody.observe(document.body, {
        childList: true,
        subtree: true,
      });
    },

    querySkinDrV2RoutinesBlock(routines) {
      // no need to observe document.body anymore - we do have a node to grab info from
      this.observerForDocumentBody.disconnect();

      this.collectSkinDrV2RoutinesData(routines);
      this.createAddToWalletButton(routines, this.onAddToWalletClick);
    },

    onAddToWalletClick() {
      AnalyticsHandler.getAnalyticsHandler().push({
        ...analyticsCommonFields,
        label: 'first step',
        module_name: 'captain_wallet_first_step',
        product_info: this.analyticsProductInfo,
        cta_name: this.labels.addToWalletButtonLabel,
      });

      this.toggleModal();
    },

    morningRoutineAnalytics() {
      AnalyticsHandler.getAnalyticsHandler().push({
        ...analyticsCommonFields,
        label: 'morning routine',
        module_name: 'captain_wallet_morning_routine',
        product_info: this.analyticsMorningProductInfo,
        cta_name: this.labels.morningRoutineButtonLabelInModal,
      });
    },

    nightRoutineAnalytics() {
      AnalyticsHandler.getAnalyticsHandler().push({
        ...analyticsCommonFields,
        label: 'night routine',
        module_name: 'captain_wallet_night_routine',
        product_info: this.analyticsNightProductInfo,
        cta_name: this.labels.nightRoutineButtonLabelInModal,
      });
    },

    fillProductInfo(productInfoLabel, label) {
      if (this.$data[productInfoLabel] === '') {
        this.$data[productInfoLabel] += `${label}`;
      } else {
        this.$data[productInfoLabel] += `|${label}`;
      }
    },

    collectSkinDrV2RoutinesData(routines) {
      const routineContent = routines.querySelectorAll('.routine-event-content');
      const [morningRoutine, nightRoutine] = routineContent;

      this.collectSkinDrV2SingleRoutineData(morningRoutine, ROUTINE_TYPE_MORNING);
      this.collectSkinDrV2SingleRoutineData(nightRoutine, ROUTINE_TYPE_NIGHT);

      this.isRoutineDataCollected = true;

      this.createWalletImageAndData();
    },

    collectSkinDrV2SingleRoutineData(routineNode, routineType) {
      const subtitles = routineNode.querySelectorAll('.subtitle');
      const products = routineNode.querySelectorAll('.product-detail-label');

      products.forEach((product, index) => {
        const label = product.innerText;

        this.fillProductInfo('analyticsProductInfo', label);
        this.fillRoutineProductInfo(routineType, label);

        const picture = product
          .closest('.product-detail')
          .querySelector('.product-detail-picture img')
          .getAttribute('src');
        const type = subtitles[index] ? subtitles[index].innerText : '';

        this.pushSingleRoutineData(routineType, { label, picture, type });
      });
    },

    createAddToWalletButton(nodeToAppend, callback) {
      let addToWalletButton = nodeToAppend.querySelector(`.${ADD_TO_WALLET_BUTTON_CLASS}`);

      if (!addToWalletButton) {
        addToWalletButton = document.createElement(ADD_TO_WALLET_BUTTON_TYPE);
        addToWalletButton.classList.add(ADD_TO_WALLET_BUTTON_CLASS);

        if (callback) addToWalletButton.addEventListener('click', callback);

        // until we get the URL from Sitecore API, it should stay disabled
        addToWalletButton.disabled = true;
        // since the URL is added dynamically, the button should be shown with opacity initially
        addToWalletButton.classList.add(ADD_TO_WALLET_BUTTON_LOADING_CLASS);

        addToWalletButton.innerHTML = `
          <img src="${WalletIcon}" alt="Wallet Icon" class="${ADD_TO_WALLET_BUTTON_CLASS}-img">
          ${this.labels.addToWalletButtonLabel}
        `;

        this.elementAddToWalletButton = addToWalletButton;

        const styleTag = document.createElement('style');
        const buttonStyle = this.isUsingCrm
          ? ADD_TO_WALLET_BUTTON_STYLE.replace(
              'bottom: 20px',
              `bottom: ${this.buttonBottomPosition}px`
            )
          : ADD_TO_WALLET_BUTTON_STYLE;

        styleTag.textContent = buttonStyle;
        addToWalletButton.appendChild(styleTag);

        nodeToAppend.appendChild(addToWalletButton);
      }
    },

    onSkinDrV3Event(data) {
      if (!data.detail.length) return;

      this.collectSkinDrV3SingleRoutineData(data.detail[0].steps, ROUTINE_TYPE_MORNING);
      this.collectSkinDrV3SingleRoutineData(data.detail[1].steps, ROUTINE_TYPE_NIGHT);

      this.createWalletImageAndData();

      const content = this.elementShadowRoot.getElementById('content');
      if (!content) return;
      this.createAddToWalletButton(content, this.onAddToWalletClick);
    },

    createWalletImageAndData() {
      this.generateWalletImageAndPrepareData(this.morningRoutineData, ROUTINE_TYPE_MORNING);
      this.generateWalletImageAndPrepareData(this.nightRoutineData, ROUTINE_TYPE_NIGHT);
    },

    collectSkinDrV3SingleRoutineData(routine, routineType) {
      routine.forEach((item) => {
        const label = item.products[0].label;
        const type = '';
        const ean = item.products[0].data.EAN;
        const pdpUrl = item.products[0].data.pdpUrl;
        const picture = item.products[0].data.packshotImageUrl;
        const analyticsLabel = `${item.products[0].label}::${item.products[0].data.EAN}`;

        this.pushSingleRoutineData(routineType, { label, type, ean, pdpUrl, picture });

        // needed for separate morning and night product data analytics
        this.fillRoutineProductInfo(routineType, analyticsLabel);

        // needed for collective morning and night product data analytics
        this.analyticsProductInfo = `${this.analyticsMorningProductInfo}|${this.analyticsNightProductInfo}`;
      });
    },

    fillRoutineProductInfo(routineType, label) {
      if (routineType === ROUTINE_TYPE_MORNING) {
        this.fillProductInfo('analyticsMorningProductInfo', label);
      } else if (routineType === ROUTINE_TYPE_NIGHT) {
        this.fillProductInfo('analyticsNightProductInfo', label);
      }
    },

    pushSingleRoutineData(routineType, product) {
      if (routineType === ROUTINE_TYPE_MORNING) {
        this.morningRoutineData.push({ ...product });
      } else if (routineType === ROUTINE_TYPE_NIGHT) {
        this.nightRoutineData.push({ ...product });
      }
    },

    async generateWalletImageAndPrepareData(routineData, routineType) {
      try {
        if (routineType === ROUTINE_TYPE_MORNING) {
          this.morningRoutineWalletImage = await generateSingleWalletImage(
            this.morningRoutineWalletBackgroundImage,
            routineData
          );

          this.prepareSingleRoutineDataAndFetch(
            this.urlToFetch,
            this.morningRoutineData,
            this.morningRoutineWalletImage,
            this.morningCampaignId,
            ROUTINE_TYPE_MORNING
          );
        } else if (routineType === ROUTINE_TYPE_NIGHT) {
          this.nightRoutineWalletImage = await generateSingleWalletImage(
            this.nightRoutineWalletBackgroundImage,
            routineData
          );

          this.prepareSingleRoutineDataAndFetch(
            this.urlToFetch,
            this.nightRoutineData,
            this.nightRoutineWalletImage,
            this.nightCampaignId,
            ROUTINE_TYPE_NIGHT
          );
        }
      } catch (error) {
        console.error('error', error);
      }
    },

    fetchSingleRoutineData(urlToFetch, products, walletImg, campaignName) {
      const requestBody = {
        products,
        walletImg,
        campaignName,
      };

      return fetch(urlToFetch, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
        });
    },

    prepareSingleRoutineDataAndFetch(
      urlToFetch,
      routineData,
      walletImg,
      campaignName,
      routineType
    ) {
      const products = [];

      for (let i = 0; i < routineData.length; i++) {
        const productName = routineData[i]?.label;
        const productType = routineData[i]?.type;
        const ean = routineData[i]?.ean;
        const pdpUrl = routineData[i]?.pdpUrl;
        products.push({ productName, productType, ean, pdpUrl });
      }

      let that = this;

      this.fetchSingleRoutineData(urlToFetch, products, walletImg, campaignName).then((data) => {
        if (routineType === ROUTINE_TYPE_MORNING) {
          that.morningRoutineWalletUrl = data.url;
        } else if (routineType === ROUTINE_TYPE_NIGHT) {
          that.nightRoutineWalletUrl = data.url;
        }

        this.enableAddToWalletButton();
      });
    },

    enableAddToWalletButton() {
      // once we have an URL(s) from the Sitecore API - let's make button available for click
      this.elementAddToWalletButton.disabled = false;
      this.elementAddToWalletButton.classList.remove(ADD_TO_WALLET_BUTTON_LOADING_CLASS);
    },

    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);

  &__content {
    width: 80%;
    max-width: 400px;
    overflow: hidden;
    margin: auto;
    border-radius: 16px;
    text-align: center;
    z-index: 10001;
  }

  &__inner {
    position: relative;
    height: auto;
    min-height: 240px;
    padding: 50px 40px;
    color: #fff;
    background-size: cover;
    background-position: center;

    &.-morning {
      background-image: url("./assets/MorningRoutineModalBackground.png");
    }

    &.-night {
      background-image: url("./assets/NightRoutineModalBackground.png");
    }

    strong {
      display: block;
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
    }

    p {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 40px;
    }
  }

  &__close {
    top: -4px;
    right: 4px;

    .icon {
      height: 15px;
      width: 15px;
    }
  }
}

.add-to-wallet {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 50%;
  max-width: 90%;
  overflow: hidden;
  padding: 13px 15px 14px;
  border: none;
  border-radius: 23px;
  background-color: #171717;
  color: #fff;
  font-family: campton, arial, sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  transform: translateX(-50%);

  &:active,
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }

  img {
    margin-right: 15px;
    height: 20px;
  }

  span {
    white-space: nowrap;
  }
}
</style>
