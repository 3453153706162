import 'lazysizes';
import { createApp } from 'vue';
import { createStore } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';
import VueCookies from 'vue-cookies';
import VueScrollTo from 'vue-scrollto';

import '../../mctbCommerceConnector';

// RefApp
import loadZoneCustomizations from '@loreal/zone-plugin-loader';
import init from '@loreal/sisyphus';
// import RefAppRatingsReviews from '@refapp/ratings-reviews';

// Sitecore-Vue bridge
import SitecoreVueAdapter from '@Foundation/sitecore/SitecoreVueAdapter';

// Helpers
import { requireAll } from '@Foundation/utilities/helpers';

// Foundation
import Foundation from '@Foundation';
import LoadScriptModule from '@Foundation/loadscript';
import eventBus, { exposeGlobals } from '@loreal/eventbus-js';
import { tag } from '@Foundation/analyticsHandler/directives/tag';

// Feature
import AccessibilityStore from '@Foundation/accessibilityStore';
import AlertModule from '@Feature/Alert';
import ArticleShareItem from '@Feature/PageContentExt';
import BazaarVoiceModule from '@Feature/Bazaarvoice';
import BeautyMagModule from '@Feature/BeautyMag';
import CarouselModule from '@Feature/Carousel';
import ColorDropdown from '@Feature/ColorDropdown';
import ColorSelector from '@Feature/ColorSelector';
import CommerceConnector from '@Feature/CommerceConnector';
import CookieBanner from '@Feature/PageContentExt';
import FilterModule from '@Feature/Filter';
import GenericForm from '@Feature/GenericForm';
import HaircareDiag from '@Feature/HaircareDiag';
import HaircolorDiag from '@Feature/HaircolorDiag';
import HaircolorTreat from '@Feature/HaircolorTreat';
import HeaderLogo from '@Feature/HeaderLogo';
import Iframepopup from '@Feature/IframePopup';
import InstagramComponent from '@Feature/Instagram';
import likebutton from '@Feature/LikeButton';
import LiveChat from '@Feature/LiveChat';
import ModalModule from '@Feature/Modal';
import NavigationModule from '@Feature/Navigation';
import NewsletterModule from '@Feature/NewsLetter';
import NotificationModule from '@Feature/Notification';
import OapAccountNavigation from '@Feature/OapAccountNavigation';
import OapBadge from '@Feature/OapBadge';
import OapBeautyGeniusWrapper from '@Feature/OapBeautyGeniusWrapper';
import OapBeautyProfile from '@Feature/OapBeautyProfile';
import OapBeforeAfter from '@Feature/OapBeforeAfter';
import OapCard from '@Feature/OapCard';
import OapCardPopin from '@Feature/OapCardPopin';
import OapCarouselControls from '@Feature/CarouselControls';
import OapContrastSwitcher from '@Feature/OapContrastSwitcher';
import OapChannelAdvisor from '@Feature/OapChannelAdvisor';
import OapConcerns from '@Feature/OapConcerns';
import OapCountdown from '@Feature/OapCountdown';
import OapEventfulTextInput from '@Feature/OapEventfulTextInput';
import OapDsfAppSkindrWrapper from '@Feature/OapDsfAppSkindrWrapper';
import OapDsfAppVtoWrapper from '@Feature/OapDsfAppVtoWrapper';
import OapDsfAppWrapper from '@Feature/OapDsfAppWrapper';
import OapFavorite from '@Feature/OapFavorite';
import OapFloatingIsland from '@Feature/OapFloatingIsland';
import OapFlowplayer from '@Feature/OapFlowplayer';
import OapFocusTrap from '@Feature/OapFocusTrap';
import OapGeolocationPopin from '@Feature/OapGeolocationPopin';
import OapIframe from '@Feature/OapIframe';
import OapImmersiveBeforeAfter from '@Feature/OapImmersiveBeforeAfter';
import OapImmersiveCopyOnVideo from '@Feature/OapImmersiveCopyOnVideo';
import OapImmersiveHotspots from '@Feature/OapImmersiveHotspots';
import OapImmersiveParallax from '@Feature/OapImmersiveParallax';
import OapLoader from '@Feature/OapLoader';
import OapMarquee from '@Feature/OapMarquee';
import OapMakeTheSwitch from '@Feature/MakeTheSwitch';
import OapMarkupRenderingDelay from '@Feature/OapMarkupRenderingDelay';
import OapMediaCarousel from '@Feature/OapMediaCarousel';
import OapMediaCover from '@Feature/OapMediaCover';
import OapMenShadeMatcher from '@Feature/MenShadeMatcher';
import OapPopin from '@Feature/OapPopin';
import OapPopup from '@Feature/OapPopup';
import OapProductIngredients from '@Feature/OapProductIngredients';
import OapProductVariantSelector from '@Feature/ProductVariantSelector';
import OapPromoBar from '@Feature/OapPromoBar';
import OapRoutineBuilder from '@Feature/RoutineBuilder';
import OapScroller from '@Feature/OapScroller';
import OapSkindrAppWrapper from '@Feature/OapSkindrAppWrapper';
import OapSlider from '@Feature/OapSlider';
import OapStories from '@Feature/OapStories';
import OapStrikethroughScrolling from '@Feature/OapStrikethroughScrolling';
import OapSustainability from '@Feature/OapSustainability';
import OapTabComponent from '@Feature/OapTab';
import OapToastNotification from '@Feature/OapToastNotification';
import OapUsage from '@Feature/OapUsage';
import OapVideoImmersive from '@Feature/OapVideoImmersive';
import OneTrustCookieHelper from '@Feature/OneTrust';
import Overlay from '@Feature/Overlay';
import productModule from '@Feature/ProductModule';
import RealDialog from '@Feature/RealDialog';
import RevTrax from '@Feature/RevTrax';
import RrStarRatings from '@Feature/RrStarRatings';
import Search from '@Feature/Search';
import SearchSuggestions from '@Feature/SearchSuggestions';
import Sfmc from '@Feature/SFMC';
import Slider from '@Feature/Slider';
import VideoPlaceholder from '@Feature/VideoPlaceholder';
import VirtualTryOn from '@Feature/VirtualTryOn';
import VueModels from '@Feature/VueModels';
import OapPageNavigation from '@Feature/OapPageNavigation';
import OapPromoCard from '@Feature/OapPromoCard';
// 3rd party
import { Tabs, Tab } from 'vue3-tabs-component';
import ToastedPlugin from '@Feature/OapToastNotification/code/plugin';

const VueTabsPlugin = {
  install(app) {
    app.component('Tabs', Tabs);
    app.component('Tab', Tab);
  },
};

exposeGlobals();

const app = createApp({});
app.config.compilerOptions.whitespace = 'preserve';
app.config.performance = true;

// Bootstrap Vue
app.use(ToastedPlugin);
app.use(VueTabsPlugin);

// Register directives
Object.entries({
  ...CommerceConnector.directives,
  ...Foundation.directives,
  ...LoadScriptModule.directives,
  ...NavigationModule.directives,
  ...Search.directives,
  tag,
}).forEach(([name, directive]) => app.directive(name, directive));

// Register components
Object.entries({
  ...AlertModule.components,
  ...ArticleShareItem.components,
  ...BazaarVoiceModule.components,
  ...BeautyMagModule.components,
  ...CarouselModule.components,
  ...ColorDropdown.components,
  ...ColorSelector.components,
  ...CommerceConnector.components,
  ...CookieBanner.components,
  ...FilterModule.components,
  ...Foundation.components,
  ...GenericForm.components,
  ...HaircareDiag.components,
  ...HaircolorDiag.components,
  ...HaircolorTreat.components,
  ...HeaderLogo.components,
  ...Iframepopup.components,
  ...InstagramComponent.components,
  ...likebutton.components,
  ...LiveChat.components,
  ...LoadScriptModule.components,
  ...ModalModule.components,
  ...NavigationModule.components,
  ...NewsletterModule.components,
  ...NotificationModule.components,
  ...OapAccountNavigation.components,
  ...OapBadge.components,
  ...OapBeautyGeniusWrapper.components,
  ...OapBeautyProfile.components,
  ...OapBeforeAfter.components,
  ...OapCard.components,
  ...OapCardPopin.components,
  ...OapCarouselControls.components,
  ...OapContrastSwitcher.components,
  ...OapChannelAdvisor.components,
  ...OapConcerns.components,
  ...OapCountdown.components,
  ...OapEventfulTextInput.components,
  ...OapDsfAppSkindrWrapper.components,
  ...OapDsfAppVtoWrapper.components,
  ...OapDsfAppWrapper.components,
  ...OapFavorite.components,
  ...OapFloatingIsland.components,
  ...OapFlowplayer.components,
  ...OapFocusTrap.components,
  ...OapGeolocationPopin.components,
  ...OapIframe.components,
  ...OapImmersiveBeforeAfter.components,
  ...OapImmersiveCopyOnVideo.components,
  ...OapImmersiveHotspots.components,
  ...OapImmersiveParallax.components,
  ...OapLoader.components,
  ...OapMarquee.components,
  ...OapMakeTheSwitch.components,
  ...OapMarkupRenderingDelay.components,
  ...OapMediaCarousel.components,
  ...OapMediaCover.components,
  ...OapMenShadeMatcher.components,
  ...OapPopin.components,
  ...OapPopup.components,
  ...OapProductIngredients.components,
  ...OapProductVariantSelector.components,
  ...OapPromoBar.components,
  ...OapRoutineBuilder.components,
  ...OapScroller.components,
  ...OapSkindrAppWrapper.components,
  ...OapSlider.components,
  ...OapStories.components,
  ...OapStrikethroughScrolling.components,
  ...OapSustainability.components,
  ...OapTabComponent.components,
  ...OapToastNotification.components,
  ...OapUsage.components,
  ...OapVideoImmersive.components,
  ...OneTrustCookieHelper.components,
  ...Overlay.components,
  ...productModule.components,
  ...RealDialog.components,
  ...RevTrax.components,
  ...RrStarRatings.components,
  ...SearchSuggestions.components,
  ...Sfmc.components,
  ...Slider.components,
  ...VideoPlaceholder.components,
  ...VirtualTryOn.components,
  ...VueModels.components,
  ...OapPageNavigation.components,
  ...OapPromoCard.components,
}).forEach(([name, component]) => app.component(name, component));

Object.entries({
  ...Foundation.filters,
}).forEach(([name, filter]) => app.filter(name, filter));

const store = createStore({
  plugins: [...VirtualTryOn.plugins],
  modules: {
    ...OapBeautyProfile.modules,
    ...OapFavorite.modules,
    ...OapMakeTheSwitch.modules,
    ...OapTabComponent.modules,
    ...productModule.modules,
    ...VirtualTryOn.modules,
    ...AccessibilityStore.modules,
  },
});

app.use(SimpleVueValidation);
app.use(VueCookies);
app.use(VueScrollTo);
app.use(store);

// RefApp Components / Plugins
window.RefAppMctb && app.use(window.RefAppMctb);
window.RefAppStoreLocator && app.use(window.RefAppStoreLocator);
window.RefAppRatingsReviews && app.use(window.RefAppRatingsReviews);
// app.use(RefAppRatingsReviews);

/**
 * Initialize any zone-level customizations.
 * Every zone/country can create their own Vue components and make them available
 * to their code through the use of a Vue.js Plugin
 *
 * @see https://vuejs.org/guide/reusability/plugins.html#writing-a-plugin
 * */
loadZoneCustomizations(app, store);
// Initialize third party loader
init();

requireAll(require.context('../../../Icons/', true, /\.svg$/));

document.addEventListener('lazybeforeunveil', (event) =>
  eventBus.emit('image.lazybeforeunveil', event)
);

SitecoreVueAdapter.initializeVueApp(app.mount.bind(app, '#main-container'));
